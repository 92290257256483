import { breakpoints, colors } from "../../../../theme/theme";
import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { createClipPathPolygon, updateClipPaths } from "helpers/clipPath";
import { FC, useEffect, useRef, useState } from "react";
import { get, isObject } from "lodash-es";
import { getEdgeSettings } from "components/wrapper/components";
import { isLandingpage } from "helpers/routing/utils";
import { LoadingSpinner } from "components/loading-spinner";
import { PageTree, WrapperComponentProps } from "constants/types";
import { sanitize } from "helpers/text-processing";
import { Shape } from "components/shape";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useMediaQuery } from "react-responsive";
import { useSlugPath } from "helpers/routing";
import classNames from "classnames";
import HeadingWrapper from "components/wrapper/components/heading";
import Link from "components/link";

const findPageInNav = (pageId: string, nav: PageTree): PageTree | null => {
  if (nav.id === pageId) {
    return nav;
  } else {
    if (nav.children) {
      for (const item of Object.entries(nav.children)) {
        const found = findPageInNav(pageId, item?.[1]);
        if (found) return found;
      }
    } else return null;
  }
};

interface NavigationWrapperProps extends WrapperComponentProps {
  button_ui_element?: string;
  custom_button?: boolean;
  enable_subtitles?: boolean;
}

const NavigationWrapper: FC<NavigationWrapperProps> = (props) => {
  let baseSubPages = [];
  if (props.render_sub_pages) {
    baseSubPages = Object.values(
      findPageInNav(props.page.id, props.nav)?.children || [],
    );
  }
  const { locale, language } = useLocale();
  const cardNavigationRefs = useRef<HTMLDivElement[]>([]);
  const cardNavigationHeaderRef = useRef<HTMLDivElement>();
  const [subPages, setSubPages] = useState(props.pages);
  const [isLoading, setIsLoading] = useState(true);
  const [headerClipPath, setHeaderClipPath] = useState<string>(null);
  const [clipPath, setClipPath] = useState<string | string[]>(null);

  const slugPath = useSlugPath();
  const landingPage = isLandingpage(slugPath);

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  useEffect(() => {
    if (props.render_sub_pages) {
      void fetch(`/api/pages`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ids: baseSubPages.map((page) => ["id", page.id]),
          locale,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const temp = [];
          data.page.map((page) => {
            page._url = baseSubPages.find((basePage) => basePage.id === page.id)
              ?.url;
            temp[
              baseSubPages.findIndex((basePage) => basePage.id === page.id)
            ] = page;
            return temp;
          });
          setSubPages(temp);
          setIsLoading(false);
        });
    } else {
      setSubPages(props.pages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, props.page?.id]);

  const uiButton = props.custom_button ? props.button_ui_element : "ui-165";
  const [showMoreLabel, onlyEnglishBtn] = useLabels(
    [uiButton, "Show more information"],
    ["ui-274", '"(English)"'],
  );
  const { fullwidth, diagonal_border, padding, tiles_per_row } = props;

  const isSm = useMediaQuery({ query: `(max-width: ${breakpoints.md - 1}px)` });
  const isMd = useMediaQuery({ query: `(max-width: ${breakpoints.lg - 1}px)` });
  const isLg = useMediaQuery({ query: `(max-width: ${breakpoints.xl - 1}px)` });

  const [tilesPerRow, setTilesPerRow] = useState(+tiles_per_row || 4);

  const headerIsPartOfGrid = props.header_layout === "heading-left";

  useEffect(() => {
    // Only use fillwidth when at least on a Xl screen, else it would look really bad
    if (!isLg && fullwidth && subPages?.length) {
      setTilesPerRow(
        Math.min(
          subPages?.filter((page) => isObject(page))?.length +
            (headerIsPartOfGrid ? 1 : 0) || 1,
          6,
        ),
      );
    } else {
      let temporaryTilesPerRow = 0;

      if (isSm) temporaryTilesPerRow = 2; // Sm or lower
      else if (isMd) temporaryTilesPerRow = 3; // Md or lower
      // Use predefined tiles_per_row only if larger than md
      else if (+tiles_per_row) temporaryTilesPerRow = +tiles_per_row;
      else if (isLg) temporaryTilesPerRow = 4; // Lg
      else temporaryTilesPerRow = 5; // Xl

      // Diagonal is always one less
      if (diagonal_border) {
        temporaryTilesPerRow = temporaryTilesPerRow - 1;
      }

      // different behaviour for Level-Application-Selector / headerIsPartOfGrid
      if (headerIsPartOfGrid && !isSm) {
        temporaryTilesPerRow = temporaryTilesPerRow + 1;
      }

      setTilesPerRow(temporaryTilesPerRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSm, isMd, isLg, subPages?.length, headerIsPartOfGrid, diagonal_border]);

  useEffect(() => {
    const updateOnResize = () => {
      const clipPaths = updateClipPaths(
        cardNavigationRefs.current,
        8,
        tilesPerRow,
        diagonal_border,
        headerIsPartOfGrid,
      );

      setClipPath(clipPaths);

      // setHeaderClipPath for LevelApplicationSelector Header&NavCard Combo
      if (headerIsPartOfGrid && cardNavigationHeaderRef.current) {
        setHeaderClipPath(
          createClipPathPolygon(cardNavigationHeaderRef.current, 8, "start"),
        );
      }
    };

    if (cardNavigationRefs.current) {
      updateOnResize();

      window.addEventListener("resize", updateOnResize);

      return () => {
        window.removeEventListener("resize", updateOnResize);
      };
    }
  }, [cardNavigationRefs, diagonal_border, headerIsPartOfGrid, tilesPerRow]);

  const grid = (
    <div
      className={classNames(
        "card-navigation-wrapper",
        diagonal_border && "card-navigation-parallelogram",
        headerIsPartOfGrid && "grid-with-headline-3col",
      )}
      style={
        !props.render_sub_pages || !isLoading
          ? {
              display: "grid",
              gridTemplateColumns: `repeat(${tilesPerRow}, 1fr)`,
              ...(padding && { columnGap: "20px", rowGap: "20px" }),
            }
          : {}
      }
    >
      {headerIsPartOfGrid && (
        <div className="wrap-it">
          <div
            className={classNames(
              "component__navigation",
              "card-navigation",
              "row-start",
              "headline-grid",
            )}
            ref={cardNavigationHeaderRef}
            style={{ clipPath: headerClipPath }}
          >
            <Container
              edge={getEdgeSettings(props)}
              marginTop={props.margin_top}
              marginBottom={props.margin_bottom}
              background={props.background_color}
              layout={props.header_layout}
              id={`component__navigation-${props.index}`}
              key={`${locale}-navigation-${props.index}`}
            >
              <HeadingWrapper {...props} />
            </Container>
          </div>
        </div>
      )}

      {!props.render_sub_pages || !isLoading ? (
        subPages
          ?.filter((page) => isObject(page))
          ?.map((page, index) => {
            const related = get(page, "related_pc.0", null);
            const keyvisual = get(
              page,
              "key_visual.0",
              get(related, "key_visual.0", null),
            );

            // rework when statamic function is implemented
            let enableSubtitles = props.enable_subtitles;
            if (typeof enableSubtitles === "undefined") {
              enableSubtitles = true;
            }

            // yey.
            let title = get(page, "short_title", "");
            if (!title || title === "") title = get(related, "short_title", "");
            if (!title || title === "") title = get(page, "title", "");
            if (!title || title === "") title = get(related, "title", "");

            const subtitle = page?.subtitle || related?.subtitle;
            const onlyEnglish = page._lang !== language;

            const rowIndex =
              (index + 1 + (headerIsPartOfGrid ? 1 : 0)) % tilesPerRow;

            const position =
              tilesPerRow > 1
                ? rowIndex === 1
                  ? "start"
                  : rowIndex === 0
                  ? "end"
                  : "center"
                : null;

            return (
              <div
                className={classNames(
                  "wrap-it",
                  headerIsPartOfGrid && isSm && "row",
                  landingPage && "relative",
                )}
                key={index}
              >
                <div
                  className={classNames(
                    "card-navigation",
                    position && `row-${position}`,
                    headerIsPartOfGrid && isSm && !landingPage && "col-6",
                    landingPage && isMobile && "w-full",
                  )}
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    position: "relative",
                    clipPath:
                      cardNavigationRefs.current && clipPath
                        ? clipPath[index]
                        : "",
                  }}
                  ref={(el) => {
                    if (el) {
                      cardNavigationRefs.current[index] = el;
                    }
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    {keyvisual ? (
                      !headerIsPartOfGrid ? (
                        <Cloudinary
                          media={keyvisual}
                          ar="ar169"
                          background="grey-triangle"
                          fitStrategy="cover"
                        />
                      ) : (
                        <Cloudinary
                          media={keyvisual}
                          ar="ar11"
                          background="white"
                          fitStrategy="cover"
                        />
                      )
                    ) : null}
                  </div>

                  {page && page._url ? (
                    <Link
                      href={page.link ? page.link : page._url}
                      prefetch={false}
                      target={page.link ? "_blank" : null}
                    >
                      <div className="card-navigation-outer">
                        <div className="card-navigation-gradient"></div>
                        <div
                          className={classNames(
                            "card-navigation-body",
                            landingPage && "flex-column flex-center",
                          )}
                        >
                          <p
                            className="h5"
                            dangerouslySetInnerHTML={{
                              __html: sanitize(title),
                            }}
                          />
                          {landingPage ? (
                            <Button
                              className="d-block mt-3 mr-0"
                              variant="primary"
                              icon={
                                page.link
                                  ? "external-link"
                                  : "caret-right-small"
                              }
                              label={showMoreLabel.label}
                              url={page.link ? page.link : page._url}
                              target={page.link ? "_blank" : null}
                            />
                          ) : null}

                          {subtitle || !headerIsPartOfGrid ? (
                            <div className="card-navigation-content">
                              {subtitle && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: sanitize(subtitle),
                                  }}
                                />
                              )}
                              {!headerIsPartOfGrid && (
                                <Button
                                  variant="white"
                                  icon={
                                    page.link
                                      ? "external-link"
                                      : "caret-right-small"
                                  }
                                  label={
                                    showMoreLabel.label +
                                    (onlyEnglish
                                      ? ` (${onlyEnglishBtn.label})`
                                      : "")
                                  }
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  <div className="card-surface"></div>
                </div>
                {!diagonal_border ? (
                  <div
                    className={classNames(
                      "card-navigation-mobile container",
                      enableSubtitles && "has-subtitle",
                    )}
                  >
                    <Link
                      href={page.link ? page.link : page._url}
                      prefetch={false}
                      target={page.link ? "_blank" : null}
                    >
                      <div className="">
                        <Cloudinary media={keyvisual} ar="ar11" />
                      </div>
                      <div className="card-navigation-content-wrapper">
                        <div className="card-navigation-content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sanitize(title),
                            }}
                          />
                          {enableSubtitles && (
                            <p
                              className="no-margin"
                              dangerouslySetInnerHTML={{
                                __html: sanitize(subtitle),
                              }}
                            />
                          )}
                        </div>
                        <Shape
                          variant={page.link ? "external-link" : "caret-right"}
                          fill={colors.gray60}
                        />
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      "card-navigation-mobile",
                      headerIsPartOfGrid && isSm && !landingPage && "col-6",
                      landingPage &&
                      `card-navigation-mobile__landing-page position-absolute`,
                      index === subPages.length - 1 && "last-button"
                    )}
                  >
                    <p dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
                    <div className="card-navigation-content">
                      <p
                        dangerouslySetInnerHTML={{ __html: sanitize(subtitle) }}
                      />
                      <Button
                          variant={landingPage ? "primary" : "primary-outline"}
                        icon={page.link ? "external-link" : "caret-right-small"}
                        label={showMoreLabel.label}
                        url={page.link ? page.link : page._url}
                        target={page.link ? "_blank" : null}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })
      ) : props.render_sub_pages && isLoading ? (
        <LoadingSpinner />
      ) : null}
    </div>
  );

  return (
    <>
      {!headerIsPartOfGrid && (
        <Container
          edge={getEdgeSettings(props)}
          marginTop={props.margin_top}
          marginBottom={props.margin_bottom}
          background={props.background_color}
          layout={props.header_layout}
          id={`component__navigation-${props.index}`}
          className="component__navigation"
          key={`${locale}-navigation-${props.index}`}
        >
          <>
            <HeadingWrapper {...props} />
            {!fullwidth && grid}
          </>
        </Container>
      )}
      {fullwidth && grid}
    </>
  );
};

export default NavigationWrapper;
